<template>
  <item-list-view
      :api="api"
      :headers="headers"
      title="Пользователи"
      search-placeholder="Поиск по логину, pid или ФИО"
      :allowed-search="true"
      :allowed-create="false"
      :allowed-remove="false"
      update-route="User"
      :request-params="requestParams"
  >
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | dateTime }}
    </template>
    <template v-slot:item.roles="{ item }">
      <div class="roles-list">
        <div v-for="role in item.roles" :key="role" style="max-width: 120px;">
          {{ formatRoleLabel(role) }}
        </div>
      </div>
    </template>
    <template v-slot:filters>
      <v-col cols="4">
        <user-role-select
          v-model="requestParams.roles"
          hide-details
          name="role"
          clearable
          placeholder="Роль"
        ></user-role-select>
      </v-col>
    </template>
  </item-list-view>
</template>

<script>
import api from '../../services/users'
import ItemListView from '../../components/ItemListView'
import UserRoleSelect from '@/components/User/UserRoleSelect.vue'
import { formatRoleLabel } from "@/services/auth"

export default {
  components: { ItemListView, UserRoleSelect },
  data: () => ({
    api: api,
    requestParams: {
      roles: []
    },
    formatRoleLabel,
  }),
  computed: {
    headers () {
      const rolesSelected = this.requestParams.roles && this.requestParams.roles.length
      return [
        { text: 'Логин', value: 'username' },
        { text: 'Дата регистрации', value: 'createdAt' },
        { text: 'ФИО', value: 'fullName' },
        { text: 'PID', value: 'recordId' },
        rolesSelected ? { text: 'Роли', value: 'roles' } : null,
        { text: '', value: 'action' }
      ].filter((item) => item)
    }
  }
}
</script>

<style scoped>
.roles-list {
  padding: 0.5em 0;
  line-height: 1.1em;
  display: flex;
  flex-direction: column;
  grid-gap: 0.55em;
}
</style>
