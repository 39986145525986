<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :items="items"
    item-value="value"
    item-text="text"
    multiple
  />
</template>

<script>
import {
  ROLES_LIST,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_EMPLOYEE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_CHIEF_DOCTOR_EMPLOYEE,
  ROLE_HR_EMPLOYEE,
  ROLE_IDDQD,
  ROLE_GUEST_EMPLOYEE,
  ROLE_UNREGISTERED_CLIENT,
  ROLE_CLIENT,
} from '@/models/AuthUser'
import { formatRoleLabel } from '@/services/auth'

const orderList = [
  ROLE_IDDQD,
  ROLE_ADMIN_EMPLOYEE,
  ROLE_EDITOR_EMPLOYEE,
  ROLE_CHIEF_DOCTOR_EMPLOYEE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_HR_EMPLOYEE,
  ROLE_EMPLOYEE,
  ROLE_GUEST_EMPLOYEE,
  ROLE_CLIENT,
  ROLE_UNREGISTERED_CLIENT,
]

export default {
  computed: {
    items () {
      return ROLES_LIST.map((role) => {
        const order = orderList.indexOf(role)
        return {
          value: role,
          text: formatRoleLabel(role),
          order: order > -1 ? order : Infinity
        }
      }).sort((a, b) => a.order - b.order)
    }
  }
}
</script>
